import {
    ATHOM_SANITY_ID,
    CustomProjectType,
    ERMAZD_SANITY_ID,
    NewsArticle,
    Project,
    SANITY_API_VERSION,
} from '@ermazd/common'
import imageUrlBuilder from '@sanity/image-url'
import {createClient, groq} from 'next-sanity'

import {
    AboutPage,
    ContactPage,
    Equipment,
    EquipmentWithStudioSlugs,
    FeaturedArtist,
    GearPage,
    HomePage,
    Studio,
    StudioPage,
    StudioWithEquipmentAndArtists,
    TeamMember,
} from '@/schemas/index'

export const ermazdClient = createClient({
    projectId: ERMAZD_SANITY_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: SANITY_API_VERSION,
    useCdn: false,
    token: process.env.ERMAZD_SANITY_TOKEN,
})

export const athomClient = createClient({
    projectId: ATHOM_SANITY_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: SANITY_API_VERSION,
    useCdn: false,
    token: process.env.ATHOM_SANITY_TOKEN,
})

export const ermazdImageBuilder = imageUrlBuilder(ermazdClient)
export const athomImageBuilder = imageUrlBuilder(athomClient)

export const imageQuery = groq`{...,asset->{...}}`

export async function getStudios(): Promise<Studio[]> {
    return ermazdClient.fetch(
        groq`*[_type == "studio"]{..., images[]${imageQuery}, primaryImage${imageQuery}}`
    )
}

export async function getStudioBySlug(slug: string): Promise<StudioWithEquipmentAndArtists> {
    return ermazdClient.fetch(
        groq`*[_type == "studio" && slug.current == $slug][0]{...,"equipment": equipment[]->{...}, "artists": artists[]->{...}, images[]${imageQuery}, primaryImage${imageQuery}}`,
        {slug}
    )
}

export async function getStudioSlugs(): Promise<string[]> {
    return ermazdClient.fetch(groq`*[_type == "studio"].slug.current`)
}

export async function getEquipment(): Promise<Equipment[]> {
    return ermazdClient.fetch(groq`*[_type == "studioEquipment"]{...,image${imageQuery}}`)
}

export async function getEquipmentWithStudioSlug(): Promise<EquipmentWithStudioSlugs[]> {
    return ermazdClient.fetch(
        groq`*[_type == "studioEquipment"]{..., "studios": *[_type == "studio" && references(^._id)].title, image${imageQuery}}`
    )
}

export async function getFeaturedArtists(): Promise<FeaturedArtist[]> {
    return ermazdClient.fetch(groq`*[_type == "featuredArtist"]{...,image${imageQuery}}`)
}

export async function getNewsArticles(length: number): Promise<NewsArticle[]> {
    return ermazdClient.fetch(
        groq`*[_type == "newsArticle"][0..${length - 1}]{...,image${imageQuery}}`
    )
}

export async function getAboutPage(): Promise<AboutPage> {
    return ermazdClient.fetch(groq`*[_type == "aboutPage" && _id == "aboutPage"][0]`)
}

export async function getContactPage(): Promise<ContactPage> {
    return ermazdClient.fetch(groq`*[_type == "contactPage" && _id == "contactPage"][0]`)
}

export async function getHomePage(): Promise<HomePage> {
    return ermazdClient.fetch(groq`*[_type == "homePage" && _id == "homePage"][0]`)
}

export async function getGearPage(): Promise<GearPage> {
    return ermazdClient.fetch(groq`*[_type == "gearPage" && _id == "gearPage"][0]`)
}

export async function getStudioPage(): Promise<StudioPage> {
    return ermazdClient.fetch(groq`*[_type == "studioPage" && _id == "studioPage"][0]`)
}

export async function getTeamMembers(): Promise<TeamMember[]> {
    return ermazdClient.fetch(groq`*[_type == "teamMember"]{...,image${imageQuery}}`)
}

export async function getProjects(): Promise<Project[]> {
    return ermazdClient.fetch(
        groq`*[_type == "project" && visible == true]{...,media[]${imageQuery},bannerImage${imageQuery},posterImage${imageQuery},btsMedia[]${imageQuery}} | order(featured desc)`
    )
}

export async function getProjectSlugs(): Promise<string[]> {
    return ermazdClient.fetch(groq`*[_type == "project"  && visible == true].slug.current`)
}

export async function getAthomProjects(): Promise<Project[]> {
    return athomClient.fetch(
        groq`*[_type == "project" && visible == true]{...,media[]${imageQuery},bannerImage${imageQuery},posterImage${imageQuery},btsMedia[]${imageQuery}} | order(featured desc)`
    )
}

export async function getAthomProjectSlugs(): Promise<string[]> {
    return ermazdClient.fetch(groq`*[_type == "project"  && visible == true].slug.current`)
}

export async function getProjectBySlug(slug: string): Promise<Project> {
    return ermazdClient.fetch(
        groq`*[_type == "project" && slug.current == $slug][0]{...,media[]${imageQuery},bannerImage${imageQuery},posterImage${imageQuery},btsMedia[]${imageQuery}}`,
        {slug}
    )
}

export async function getAthomProjectBySlug(slug: string): Promise<Project> {
    return athomClient.fetch(
        groq`*[_type == "project" && slug.current == $slug][0]{...,media[]${imageQuery},bannerImage${imageQuery},posterImage${imageQuery},btsMedia[]${imageQuery}}`,
        {slug}
    )
}

export async function getAnyProjectBySlug(slug: string): Promise<CustomProjectType> {
    const ermazdProject = await getProjectBySlug(slug)
    if (ermazdProject) {
        return {
            ...ermazdProject,
            source: 'ermazd',
        }
    } else {
        const athomProject = await getAthomProjectBySlug(slug)
        return {
            ...athomProject,
            source: 'athom',
        }
    }
}
