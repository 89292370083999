import {CustomProjectType, getFeaturedImage, hasFeaturedImage, SanityImage} from '@ermazd/common'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

import Footer from '@/components/Footer'
import Hero from '@/components/Hero'
import {
    athomClient,
    ermazdClient,
    getAthomProjects,
    getHomePage,
    getProjects as getErmazdProjects,
} from '@/lib/client'
import arrow_up_right from '@/public/arrow-up-right.svg'
import studio_image from '@/public/homepage_studio.png'
import hero_image from '@/public/homepage-hero.png'
import {HomePage} from '@/schemas/homePage'

export interface HomeProps {
    athomProjects: CustomProjectType[]
    ermazdProjects: CustomProjectType[]
    homePage: HomePage
}

export default function Home({athomProjects, ermazdProjects, homePage}: HomeProps) {
    const currentYear = new Date().getFullYear().toString()
    const projectClasses = ['grid-in-a', 'grid-in-b', 'grid-in-c', 'grid-in-d', 'grid-in-e']
    const heightClass = [
        'h-2/3-screen',
        'h-2/3-screen',
        'h-4/3-screen',
        'h-3/2-screen',
        'h-2-screen',
    ]
    const gridClasses = [
        'grid-areas-project-1',
        'grid-areas-project-2',
        'grid-areas-project-3',
        'grid-areas-project-4',
        'grid-areas-project-5',
    ]
    const sortedProjects = [
        ...athomProjects.filter((p) => p.featured),
        ...ermazdProjects.filter((p) => p.featured),
    ]
    const numberOfProjects = sortedProjects.slice(0, 5).length - 1
    return (
        <>
            <Head>
                <title>Ermazd</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Hero inverted={true} image={hero_image} animated={true}></Hero>
            <section className="my-12 px-2 md:px-28 flex flex-col w-full">
                <div className="hidden md:flex flex-col self-end w-full md:w-1/4">
                    {homePage.description && (
                        <>
                            <hr className="md:w-24 h-[2px] bg-crimson pb-1 mb-1" />
                            <p className="body-2">{homePage.description}</p>
                        </>
                    )}
                </div>
                <div className="w-full md:w-2/3 self-center my-4 md:my-16">
                    <h1 className="uppercase heading-1 text-center">
                        {homePage.tag ??
                            'We are a multi-media production company based in New Delhi'}
                    </h1>
                </div>
                <div className="flex flex-col self-start w-full md:w-1/4 lg:w-1/5 uppercase title-3 md:mb-4 my-4 md:mt-0">
                    <div className="flex flex-row items-end self-center">
                        <span>2020&nbsp;</span>
                        <hr className="w-16 bg-crimson h-[2px] pb-1 mb-1" />
                        <span>&nbsp;{currentYear}</span>
                    </div>
                    <span className="w-full text-center">Selected Works</span>
                </div>
                {sortedProjects.length > 0 && (
                    <div
                        className={`w-full h-full flex flex-col lg:grid auto-cols-fr auto-rows-fr ${gridClasses[numberOfProjects]} ${heightClass[numberOfProjects]}`}
                    >
                        {sortedProjects
                            .filter((p) => hasFeaturedImage(p))
                            .slice(0, 5)
                            .map((project, index) => {
                                const sanityClient =
                                    project.source === 'ermazd' ? ermazdClient : athomClient
                                const featuredImage = getFeaturedImage(project)
                                return (
                                    <div
                                        className={`${projectClasses[index]} flex flex-col w-full items-stretch justify-between relative`}
                                        key={index}
                                    >
                                        {featuredImage && (
                                            <SanityImage
                                                sanityClient={sanityClient}
                                                sanitySrc={featuredImage}
                                                alt={featuredImage?.caption ?? ''}
                                                className="w-full h-auto"
                                            />
                                        )}
                                        <Link
                                            href={`/projects/${project.slug.current}`}
                                            className="heading-3 uppercase hover:text-crimson"
                                        >
                                            {project.title}
                                        </Link>
                                        <p className="body-1">{project.shortDescription}</p>
                                    </div>
                                )
                            })}
                    </div>
                )}
                <div className="flex flex-row justify-center mt-4">
                    <Link href={'/projects'} className="button button-crimson uppercase">
                        All Projects
                    </Link>
                </div>
            </section>
            <section className="flex flex-col lg:flex-row text-white">
                <div className="hidden md:block w-1/2">
                    <Image src={studio_image} alt={'Image of an Ermazd studio'} />
                </div>
                <div className="w-full md:w-1/2 bg-black flex flex-col justify-between items-end">
                    <div className="md:mt-24 lg:mt-32 flex flex-col w-2/3 mr-4 md:mr-20">
                        <h2 className="uppercase heading-2 mb-8">Studio Space</h2>
                        <div className="flex flex-row pl-12">
                            <Link href={'/studios/'}>
                                <Image
                                    src={arrow_up_right}
                                    width={240}
                                    height={240}
                                    alt="View studios"
                                />
                            </Link>
                            <p className="body-1">
                                {homePage.studioDescription ??
                                    'ERMAZD is a multi-media production company based in New Delhi. We\n' +
                                        '                                started our journey making experimental short films under our\n' +
                                        '                                banner.'}
                            </p>
                        </div>
                    </div>
                    <div className="w-full px-4 animate-marquee">
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                        <span className="text-crimson uppercase font-presicav text-[30px] lg:text-[52px] italic overflow-x-hidden text-clip whitespace-nowrap text-center tracking-widest">
                            • WIP&nbsp;
                        </span>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export async function getStaticProps() {
    const [ermazdProjects, athomProjects, homePage] = await Promise.all([
        getErmazdProjects(),
        getAthomProjects(),
        getHomePage(),
    ])
    return {
        props: {
            ermazdProjects: ermazdProjects.map((p) => ({...p, source: 'ermazd'})),
            athomProjects: athomProjects.map((p) => ({...p, source: 'athom'})),
            homePage,
        },
    }
}
